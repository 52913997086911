import React from "react";

const Refund = () => {
  return (
    <>
      <div className="card">
        <div>
          <h1>Refund and Cancellation Policy</h1>
        </div>
        <div>
          <h1 className="privacyhead">1. Cancellation Policy</h1>

          <p style={{ color: "black", paddingLeft: "20px" }}>
            1.1. Order Cancellation by the Customer
          </p> 
          <br />
          <p className="privaytext">
            Customers may cancel their food catering order within a specified
            time frame, typically before a certain cutoff time or date mentioned
            during the order process. Cancellations made within this timeframe
            are eligible for a full or partial refund, depending on the
            circumstances.
          </p>
          <br />
          <p style={{ color: "black", paddingLeft: "20px" }}>
            1.2. Order Cancellation by the App or Vendor
          </p>
          <br />
          <p className="privaytext">
            The food catering app or the vendor may cancel an order due to
            unforeseen circumstances, such as unavailability of ingredients,
            delivery issues, or other valid reasons. In such cases, customers
            will be notified promptly, and a full refund will be initiated.
          </p>
        </div>
        <div>
          <h1 className="privacyhead">2. Refund Policy</h1>

          <p style={{ color: "black", paddingLeft: "20px" }}>
            2.1. Eligibility for Refunds
          </p>
          <br />
          <p className="privaytext">
            <b>Refunds are typically issued in the following situations:</b>
            <br />
            <br />
            1. Cancellation of an order within the specified timeframe.
            <br />
            2. Orders that were paid for but not delivered.
            <br />
            3. Duplicate orders mistakenly placed by the customer.
            <br />
            <br />
            <b>
              Also please note that amount will be refunded based on the
              conditions below:
            </b>{" "}
            <br />
            <br />
            a. If order gets cancelled from customer side before 1 month of the
            event date, 10% non-refundable deposit amount will be held and
            remaining will be processed. <br />
            b. If order gets cancelled from customer side within 1 month or more
            than 15 days of the event date, 20% non-refundable deposit amount
            will be held and remaining will be processed. <br />
            c. If order gets cancelled from customer side within less than 15
            days of the event date, the amount is non-refundable.
          </p>
          <br />
          <p style={{ color: "black", paddingLeft: "20px" }}>
            2.2. Refund Process
          </p>
          <br />
          <p className="privaytext">
            Customers must request a refund by contacting our provided contact
            information. Refunds will be processed within a specified timeframe
            (e.g., 7-15 business days) after the refund request is approved.
            Bank statement or cancelled cheque to be submitted for refund
            process at the time of refund request initiation.
          </p>
          <br />
          <p style={{ color: "black", paddingLeft: "20px" }}>
            2.3. Refund Method
          </p>
          <br />
          <p className="privaytext">
            Refunds will be issued using the same payment method used for the
            original purchase. In some cases, refunds may be provided as credits
            for future orders if preferred by the customer.
          </p>
          <br />
          <p style={{ color: "black", paddingLeft: "20px" }}>
            2.4. Non-Refundable Items
          </p>
          <br />
          <p className="privaytext">
            Certain items or services may be non-refundable. These will be
            clearly indicated during the order process.
          </p>
        </div>
        <div>
          <h1 className="privacyhead">3. Special Cases</h1>
          <p style={{ color: "black", paddingLeft: "20px" }}>
            3.1. Events and Catering Orders
          </p>
          <br />
          <p className="privaytext">
            Orders for events or large catering orders may have different
            cancellation and refund policies. Customers will be informed of
            these policies during the order process.
          </p>
          <br />
          <p style={{ color: "black", paddingLeft: "20px" }}>
            3.2. Force Majeure
          </p>
          <br />
          <p className="privaytext">
            The app and vendors are not liable for cancellations or delays
            caused by events beyond their control, such as natural disasters or
            government regulations.
          </p>
        </div>

        <div>
          <h1 className="privacyhead">4. Contact Information</h1>
          <p className="privaytext">
            Provide clear contact information for customer support, including
            email addresses and phone numbers, where customers can reach out for
            cancellation and refund requests.
          </p>
        </div>
        <div>
          <h1 className="privacyhead">5. Modifications to the Policy</h1>
          <p className="privaytext">
            The app reserves the right to modify this refund and cancellation
            policy at any time. Any changes will be communicated to customers
            through the app.
          </p>
        </div>
        <div>
          <h1 className="privacyhead">6. Applicable Law</h1>
          <p className="privaytext">
            These shall be governed by and construed in accordance with the laws
            of India. Any disputes arising from or in connection with these
            terms shall be subject to the exclusive jurisdiction of the courts
            in Puducherry.
          </p>
        </div>
      </div>
    </>
  );
};

export default Refund;
