import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="card">
      <div>
        <h1>Privacy Policy</h1>
        <p className="privaytext">
          Thank you for using Namma Virundhu (the "Website"). This Privacy
          Policy explains how information about you is collected, used, and
          disclosed by Namma Virundhu. This Privacy Policy applies to
          information we collect when you use our website and other online
          products and services that link to this Privacy Policy (collectively,
          the "Services"). We may change this Privacy Policy from time to time.
          In some cases, we may provide you with additional notice (such as
          adding a statement to our website or sending you a notification). We
          encourage you to review the Privacy Policy whenever you access the
          Services or otherwise interact with us to stay informed about our
          information practices and the choices available to you.
        </p>
      </div>
      <div>
        <h1 className="privacyhead">1. Collection of Information</h1>
        <p className="privaytext">
          We collect information you provide directly to us when you use the
          Services. For example, we collect information when you create an
          account, fill out a form, make a order, communicate with us via
          third-party platforms, participate in a promotion or survey, or
          otherwise communicate with us. The types of information we may collect
          include your name, email address, postal address, phone number,
          payment information, demographic information, and any other
          information you choose to provide.
          <br />
          <br />
          <b>
            When you use our Services, we automatically collect certain
            information, including:
          </b>
          <br />
          <br />
          - Log Information: We log information about your use of the Services,
          including the type of browser you use, access times, pages viewed,
          your IP address, and the page you visited before navigating to our
          Services.
          <br />
          <br />
          - Device Information: We collect information about the computer or
          mobile device you use to access our Services, including the hardware
          model, operating system and version, unique device identifiers, and
          mobile network information.
          <br />
          <br />- Location Information: With your consent, we may collect
          precise location data about your device using various technologies,
          such as GPS, Wi-Fi, or cell tower proximity.
        </p>
        <br />
      </div>
      <div>
        <h1 className="privacyhead">2. Use of Information</h1>
        <br />
        <br />

        <p className="privaytext">
          <b>
            We may use information about you for various purposes, including to:
          </b>
          <br />
          <br />
          - Provide, maintain, and improve our Services;
          <br />
          - Facilitate order bookings;
          <br />
          - Communicate with you about products, services, offers, promotions,
          rewards, and events offered by Namma Virundhu and others;
          <br />
          <br />
          - Monitor and analyze trends, usage, and activities in connection with
          our Services;
          <br />
          <br />
          - Detect, investigate, and prevent fraudulent transactions and other
          illegal activities and protect the rights and property of Namma
          Virundhu and others;
          <br />
          <br />
          - Personalize and improve our Services and provide advertisements,
          content, or features that match user profiles or interests.
          <br />
          <br />
        </p>
      </div>
      <div>
        <h1 className="privacyhead">3. Sharing of Information</h1>
        <br />
        <br />

        <p className="privaytext">
          <b>
            We may share information about you as follows or as otherwise
            described in this Privacy Policy:
          </b>
          <br />
          <br />
          - With vendors, consultants, and other service providers who need
          access to such information to carry out work on our behalf;
          <br />
          <br />
          - In response to a request for information if we believe disclosure is
          in accordance with, or required by, any applicable law, regulation,
          legal process, or governmental request;
          <br />
          <br />
          - If we believe your actions are inconsistent with our user agreements
          or policies, or to protect the rights, property, and safety of Namma
          Virundhu or others;
          <br />
          <br />- With your consent or at your direction, including if we notify
          you through our Services that the information you provide will be
          shared in a particular manner and you provide such information.
        </p>
        <br />
      </div>
      <div>
        <h1 className="privacyhead">4. Data Retention</h1>

        <p className="privaytext">
          We retain information for as long as necessary to fulfill the purposes
          for which we collected it, including for the purposes of satisfying
          any legal, accounting, or reporting requirements.
          <br />
        </p>
      </div>
      <div>
        <h1 className="privacyhead">5. Your Choices</h1>

        <p className="privaytext">
          You may opt-out of receiving promotional emails from Namma Virundhu by
          following the instructions in those emails. If you opt-out, we may
          still send you non-promotional communications, such as those about
          your account or our ongoing business relations.
          <br />
        </p>
      </div>
      <div>
        <h1 className="privacyhead">6. Contact Us</h1>

        <p className="privaytext">
          If you have any questions about this Privacy Policy, please contact us
          at nammavirundhu@gmail.com.
          <br />
        </p>
      </div>
      <div>
        <h1 className="privacyhead">7. Changes to this Policy</h1>

        <p className="privaytext">
          We may revise this Privacy Policy from time to time. The most current
          version of the policy will govern our use of your information and will
          always be at [website link]. If we make a change to this policy that,
          in our sole discretion, is material, we will notify you via an update
          or email to the email address associated with your account. By
          continuing to access or use the Services after those changes become
          effective, you agree to be bound by the revised Privacy Policy.
        </p>
      </div>

      <p style={{ textAlign: "center", fontSize: "30px", marginTop: "20px" }}>
        Thank you for using NammaVirundhu.
      </p>
    </div>
  );
};

export default Privacy;
