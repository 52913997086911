import React from "react";
import "./App.css";
import Navbar from "./Component/Navbar/Navbar.js";
import { Routes, Route } from "react-router-dom";
import Home from "./Component/Home/Home.js";
import About from "./Component/About/About.js";
import Contact from "./Component/Contact/Contact.js";
import Footer from "./Component/Footer/Footer.js";
import Privacy from "./Component/Privacy/Privacy.js";
import Terms from "./Component/Terms/Terms.js";
import Refund from "./Component/Refund/Refund.js";
import Admin from "./Component/Admin.js";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
