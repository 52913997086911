import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css"; // Assuming you're importing CSS styles from a file

const Footer = () => {
  return (
    <div className="footer">
  <div className="sb_footer section_padding">
      <div className="sb_footer-links">
      <div className="sb_footer-links-div">
        <h4>Conditions</h4>
        <a href="/privacy">
          <p>Privacy Policy</p>
        </a>
        <a href="/terms">
          <p>Terms & Conditions</p>
        </a>
        <a href="/refund">
          <p>Refund Policy</p>
        </a>
      </div>
      <div className="sb_footer-links-div">
        <h4>Quick Links</h4>
        <a href="/">
          <p>Home</p>
        </a>
        <a href="/about">
          <p>About</p>
        </a>
        <a href="/contact">
          <p>Contact Us</p>
        </a>
      </div>
      <div className="sb_footer-links-div">
        <h4>Social Media</h4>
        <a href="https://www.facebook.com" className="FooterLink">
              <FontAwesomeIcon icon={faFacebookF} className="socialicon" />
              <span>Facebook</span>
            </a>
            <a href="https://www.instagram.com" className="FooterLink">
              <FontAwesomeIcon icon={faInstagram} className="socialicon" />
              <span>Instagram</span>
            </a>
      </div>
      <div className="sb_footer-links-div">
        <h4>Contact Us</h4>
        <a href="mailto:demo@gmail.com" className="FooterLink">
              <FontAwesomeIcon icon={faEnvelope} className="contacticon" />
              <span>nammavirundhu@gmail.com</span>
            </a>
            <a href="tel:+917867890564" className="FooterLink">
              <FontAwesomeIcon icon={faPhone} className="contacticon" />
              <span>+91 9003750387</span>
            </a>
      </div>
      </div>
    </div>
  
  <div className="CopyrightText">
        <p>
        © {new Date().getFullYear()} NammaVirundhu. All Rights Reserved.
        </p>
      </div>
</div>
    
  );
};

export default Footer;



