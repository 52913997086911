import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div>
      <div className="card">
        <div>
          <h1>TERMS AND CONDITIONS</h1>
          <p className="privaytext">
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            document is published in accordance with the provisions of Rule 3
            (1) of the Information Technology (Intermediaries guidelines) Rules,
            2011 that require publishing the rules and regulations, privacy
            policy and Terms of Use for access or usage of Namma Virundhu
            application for website,mobile and handheld devices.
          </p>
        </div>
        <div>
          <h1 className="privacyhead">1. Acceptance of Terms</h1>
          <p className="privaytext">
            By accessing or using the Namma Virundhu food catering order app
            ("the App"), you agree to comply with and be bound by these Terms
            and Conditions. Please read these Terms of Use carefully before you
            use the services. If you do not agree to these Terms of Use, you may
            not use the services on the Platform, and we request you to
            uninstall the App.
          </p>
          <br />
        </div>
        <div>
          <h1 className="privacyhead">2. Registration and User Accounts</h1>

          <p className="privaytext">
            2.1. You must register for an account to use certain features of the
            App. You are responsible for maintaining the confidentiality of your
            account information and are fully responsible for all activities
            that occur under your account.
            <br />
            <br />
            2.2. You agree to provide accurate, current, and complete
            information during the registration process and to update such
            information to keep it accurate, current, and complete.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">3. Ordering and Payment</h1>

          <p className="privaytext">
            3.1. Users can place food orders through the App. Payment for orders
            may be required at the time of placing order and remaining full
            payment before 15 days of order execution date.
            <br />
            <br />
            3.2. Advance payment to the catering order is 50% at the time of
            placing the order with minimum one month before the date of the
            event.
            <br />
            <br />
            3.3. Payment processing on the App is facilitated by a third-party
            payment gateway. Users are required to provide accurate payment
            information, including credit card or digital wallet details.
            <br />
            <br />
            3.4. Payment information provided to the App is securely processed
            by the payment gateway, and the App does not store sensitive payment
            information.
          </p>
          <br />
        </div>
        <div>
          <h1 className="privacyhead">4. Cancellations and Refunds</h1>

          <p className="privaytext">
            4.1. Users should review the Cancellation and refund policies before
            placing an order.
            <br />
            <br />
            4.2. Refund terms: The advance payment made to the catering order
            will be refunded based on the terms mentioned in the cancellation
            and refund policy.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">5. Privacy</h1>

          <p className="privaytext">
            The collection and use of personal information are governed by our
            Privacy Policy, which is incorporated into these Terms and
            Conditions by reference. By using the App, you consent to the
            collection and use of your personal information as described in the
            Privacy Policy.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">6. Payment Gateway</h1>

          <p className="privaytext">
            Payment processing on the App is facilitated by a third-party
            payment gateway. Users are subject to the terms and conditions of
            the payment gateway provider when making payments through the App.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">7. Intellectual Property</h1>

          <p className="privaytext">
            7.1. The content and materials provided through the App, including
            but not limited to text, graphics, logos, and software, are
            protected by copyright and other intellectual property laws.
            <br />
            <br />
            7.2. Users may not use, copy, reproduce, modify, distribute, or
            display any of the App's content without the prior written consent
            of Namma Virundhu.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">8. Termination</h1>

          <p className="privaytext">
            Namma Virundhu reserves the right to terminate or suspend user
            accounts and access to the App at our sole discretion, with or
            without notice, for any reason, including but not limited to
            violation of these Terms and Conditions.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">9. Disclaimer of Warranties</h1>
          <p className="privaytext">
            The App is provided "as is" and without warranties of any kind,
            either express or implied, including but not limited to the implied
            warranties of merchantability, fitness for a particular purpose, or
            non-infringement.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">10. Limitation of Liability</h1>

          <p className="privaytext">
            Namma Virundhu shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages, or any loss of profits
            or revenues, whether incurred directly or indirectly, or any loss of
            data, use, goodwill, or other intangible losses, resulting from your
            use or inability to use the App.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">11. Changes to Terms</h1>

          <p className="privaytext">
            Namma Virundhu reserves the right to modify or revise these Terms
            and Conditions at any time. Any changes will be posted on the App,
            and it is your responsibility to review them periodically.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">12. Governing Law and Jurisdiction</h1>

          <p className="privaytext">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of India. Any disputes arising from or in
            connection with these terms shall be subject to the exclusive
            jurisdiction of the courts in Puducherry.
            <br />
          </p>
        </div>
        <div>
          <h1 className="privacyhead">13. Contact Information</h1>
          <p className="privaytext">
            If you have any questions about these Terms and Conditions, please
            contact us at nammavirundhu@gmail.com or mobile no: 9003750387
            <br />
            <br />
            By using the Namma Virundhu food catering app, you acknowledge that
            you have read, understood, and agreed to these Terms and Conditions.
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
