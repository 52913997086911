import { useState } from "react";
import "./Navbar.css";
import { NavLink,Link } from "react-router-dom";
import { IoMenu, IoClose } from 'react-icons/io5';
import { useMediaQuery } from 'react-responsive';
import logo from  '../../Component/assets/logo.png';
// import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "1150px" });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const renderNavLinks = () => {
    const listClassName = isMobile ? "nav__list" : "nav__list__web";
    const linkClassName = "nav__link";
    return (
      <ul className={isMenuOpen ? `${listClassName} open` : listClassName}>
        <li>
          <NavLink to="/" className={linkClassName} onClick={closeMobileMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className={linkClassName} onClick={closeMobileMenu}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" className={linkClassName} onClick={closeMobileMenu}>
Contact Us          </NavLink>
        </li>
        
      </ul>
    );
  };

  return (
    <header className="header">
      <nav className="nav container">
        <Link to="/">
          <img src={logo} alt="Logo" className="nav__logo" />
        </Link>
        {isMobile && (
          <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            <IoMenu />
          </div>
        )}
        {isMobile ? (
          <div className={`nav__menu ${isMenuOpen ? "show-menu" : ""}`} id="nav-menu">
            {renderNavLinks()}
            <div className="nav__close" id="nav-close" onClick={toggleMenu}>
              <IoClose />
            </div>
          </div>
        ) : (
          renderNavLinks()
        )}
      </nav>
    </header>
  );
};
export default Navbar;
