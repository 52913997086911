import React from "react";
import "./Home.css";
// import Carousel from "../../Carousel/Carousel";
import slide2 from "../assets/slide2.png";
import food2 from '../assets/food2.jpeg'

const Home = ({ icon, title, description }) => {
  return (
    <>
      {/* <div style={{ marginTop: "1px", height: "auto" }}>
        <Carousel />
      </div>  */}

      <div className="section">
        <div className="section-image">
          <img src={slide2} alt="Food" />
        </div>
        <div className="section-content">
          <h2>
            Welcome To{" "}
            <span role="img" aria-label="restaurant">
              🍴
            </span>{" "}
            NammaVirundhu
          </h2>
          <h1>Food Is An Important Part Of A Balanced Diet</h1>
          <p style={{ color: "#858687" }}>
            Namma Virundhu, a platform celebrating authentic South Indian
            cuisine, offers a rich tapestry of flavors, traditions, and culinary
            expertise. From the bustling streets of Tamil Nadu, Namma Virundhu
            brings the essence of Traditional cuisine to life, one dish at a
            time. At the heart of Namma Virundhu lies a deep reverence for
            tradition. Each recipe is a testament to generations of culinary
            wisdom passed down through the ages. From aromatic spices to vibrant
            herbs, every ingredient is carefully selected to create dishes that
            are not just meals, but experiences.
          </p>
          <p style={{ color: "#858687" }}>
            But beyond the flavors and aromas, Namma Virundhu is also a
            celebration of community. It's a place where friends and family come
            together to share a meal, to laugh, and to create memories. Whether
            it's a festive & wedding gathering or a simple weekday dinner, Namma
            Virundhu brings people closer through the universal language of
            food. Whether you're planning a corporate luncheon, a wedding
            reception, or a private dinner party, we're here to ensure every
            bite is a memorable one. So come, join us on a culinary journey
            through the heart and soul of Tradditional. Experience the magic of
            Namma Virundhu, where every meal is a celebration of flavor,
            tradition, and community. In a world that's constantly changing,
            Namma Virundhu serves as a reminder of the timeless beauty of
            tradition
          </p>
          {/* <div className="buttons">
          <button className="learn-more">Learn More</button>
          <button className="watch-now">Watch Now</button>
        </div> */}
        </div>
      </div>
      <div>
      <h1 style={{ textAlign: "center" }}>What We Do</h1>
        <div className="section">
          <div className="section-content">
            
            <p style={{ color: "#858687" }}>
              Namma Virundhu offers a unique and unforgettable experience for
              weddings and events by bringing traditional cooking right to your
              venue. We specialize in Traditional cuisine, with a particular
              focus on our signature dish, Biryani, steeped in the rich
              traditions of our heritage.
              Imagine the aroma of spices filling the air as our skilled chefs prepare Biryani right before your eyes, infusing each grain of rice with flavor and history. Our commitment to tradition ensures that every dish is a reflection of our cultural heritage, transporting your guests on a culinary journey through South India.
            </p>
            <p style={{ color: "#858687" }}>
            But we don't just stop at cooking. At Namma Virundhu, we understand that hosting an event can be stressful, which is why we take care of all your food-related needs. From cooking and serving to ensuring smooth operations throughout the event, our dedicated team allows you and your guests to focus on what truly matters - enjoying the celebration.<br/>
            Whether it's a wedding, a corporate event, or a family gathering, Namma Virundhu adds a touch of authenticity and warmth to every occasion. Let us bring the flavors of Traditional Briyani to your special day, creating memories that will last a lifetime.
            </p>

            {/* <div className="buttons">
          <button className="learn-more">Learn More</button>
          <button className="watch-now">Watch Now</button>
        </div> */}
          </div>
          <div className="section-image">
            <img src={food2} alt="Food" />
          </div>
        </div>
      </div>
      <div className="restaurant-container">
        <h1 style={{ textAlign: "center" }}>Services</h1>
        <div className="features">
          <div className="feature-item">
            <span className="icon">👨‍🍳</span>
            <h3>Traditional Chefs</h3>
            <p style={{ color: "#858687" }}>
            Our traditional expert chefs are masters of their craft

            </p>
          </div>
          <div className="feature-item">
            <span className="icon">🍽️</span>
            <h3>Quality Food</h3>
            <p style={{ color: "#858687" }}>
            we source only the finest, freshest, and highest-quality ingredients for our dishes.
            </p>
          </div>
          <div className="feature-item">
            <span className="icon">🛒</span>
            <h3>Online Order</h3>
            <p style={{ color: "#858687" }}>
            With Namma Virundhu, you can book your event with confidence,
            </p>
          </div>
          <div className="feature-item">
          <span className="icon">📧</span>
            <h3>24/7 Service</h3>
            <p style={{ color: "#858687" }}>
            You can reach our dedicated support service team at nammavirundhu@gmail.com. 
            </p>
          </div>
        </div>
        {/* <div className="about-us">
          <h2>
            Welcome to{" "}
            <span role="img" aria-label="restaurant">
              🍴
            </span>{" "}
            Restoran
          </h2>
          <p>
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos erat ipsum et lorem et sit, sed stet lorem
            sit.
          </p>
          <p>
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos erat ipsum et lorem et sit, sed stet lorem
            sit.
          </p>
          <div className="experience">
            <div className="years">
              <h3>15</h3>
              <p>
                Years of <br /> EXPERIENCE
              </p>
            </div>
            <div className="chefs">
              <h3>50</h3>
              <p>
                Popular <br /> MASTER CHEFS
              </p>
            </div>
          </div>
          <button>Read More</button>
        </div>
        <div className="gallery"></div> */}
      </div>
    </>
  );
};

export default Home;
