import React, { useEffect, useState } from 'react';

export default function Admin() {
  const [currentDomain, setCurrentDomain] = useState('');

  useEffect(() => {
    // Get the current domain name
    setCurrentDomain(window.location.hostname);

    // Set a timeout to redirect after 3 seconds (3000 ms)
    const timer = setTimeout(() => {
      window.location.href = 'http://175.41.177.36/#/auth';
    }, 3000);

    // Cleanup timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <p>Current Domain: {currentDomain}</p>
    </div>
  );
}

