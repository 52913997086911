import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "./Contact.css";
import contact from '../assets/contact.png'

const Contact = () => {
  return (
    <div className="contact-info">
      <div className="image-section">
        <img src={contact} alt="Contact" className="contact-image" />
      </div>
      <div className="info-section">
        <h2>Contact Info.</h2>
        <div className="info-item">
          <strong>ABI-Tech Solution</strong>
          <p>
            Email:{" "}
            <a href="mailto:nammvirundhu@gmail.com">nammavirundhu@gmail.com</a>
          </p>
        </div>
        <div className="info-item">
          <strong>Address</strong>
          <p>Puducherry - 605011</p>
        </div>
        <div className="info-item">
          <strong>Let's Talk</strong>
          <p>
            <a href="tel:+919003750387">+91 90037 50387</a>
          </p>
        </div>
        <div className="info-item">
          <strong>Visit Us.</strong>
          <p>
            <a href="https://www.facebook.com" className="FooterLink">
              <FontAwesomeIcon
                icon={faFacebookF}
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              />
              <span style={{paddingLeft:'10px', marginTop:'10px'}}>Facebook</span>
            </a>
            <a href="https://www.instagram.com" className="FooterLink">
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ paddingLeft: "9px", marginTop: "10px" }}
              />
              <span style={{paddingLeft:'9px', marginTop:'10px'}}>Instagram</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
