import React from 'react';
import slide2 from "../assets/slide2.png";
import food2 from '../assets/food2.jpeg'

const About = () => {
    return (
        <>
          {/* <div style={{ marginTop: "1px", height: "auto" }}>
            <Carousel />
          </div>  */}
    
          {/* <div className="section">
            <div className="section-image">
              <img src={slide2} alt="Food" />
            </div>
            <div className="section-content">
              <h2>
                Welcome To{" "}
                <span role="img" aria-label="restaurant">
                  🍴
                </span>{" "}
                NammaVirundhu
              </h2>
              <h1>Food Is An Important Part Of A Balanced Diet</h1>
              <p style={{ color: "#858687" }}>
                Namma Virundhu, a platform celebrating authentic South Indian
                cuisine, offers a rich tapestry of flavors, traditions, and culinary
                expertise. From the bustling streets of Tamil Nadu, Namma Virundhu
                brings the essence of Traditional cuisine to life, one dish at a
                time. At the heart of Namma Virundhu lies a deep reverence for
                tradition. Each recipe is a testament to generations of culinary
                wisdom passed down through the ages. From aromatic spices to vibrant
                herbs, every ingredient is carefully selected to create dishes that
                are not just meals, but experiences.
              </p>
              <p style={{ color: "#858687" }}>
                But beyond the flavors and aromas, Namma Virundhu is also a
                celebration of community. It's a place where friends and family come
                together to share a meal, to laugh, and to create memories. Whether
                it's a festive & wedding gathering or a simple weekday dinner, Namma
                Virundhu brings people closer through the universal language of
                food. Whether you're planning a corporate luncheon, a wedding
                reception, or a private dinner party, we're here to ensure every
                bite is a memorable one. So come, join us on a culinary journey
                through the heart and soul of Tradditional. Experience the magic of
                Namma Virundhu, where every meal is a celebration of flavor,
                tradition, and community. In a world that's constantly changing,
                Namma Virundhu serves as a reminder of the timeless beauty of
                tradition
              </p>
              {/* <div className="buttons">
              <button className="learn-more">Learn More</button>
              <button className="watch-now">Watch Now</button>
            </div> */}
            {/* </div>
          </div> *} */}
          <div>
          <h1 style={{ textAlign: "center" }}>About Us</h1>
            <div className="section">
              <div className="section-content">
            
                <p style={{ color: "#858687" }}>
                Namma Virundhu is not just a platform for South Indian cuisine; it's a celebration of culture, tradition, and community through the universal language of food. Whether you're planning a wedding reception, a corporate luncheon, or a private dinner party, Namma Virundhu adds a touch of heritage to your special day. We understand the importance of creating memories that last a lifetime, and we strive to make each event unique and memorable.<br/><br/>
                Our mission is to bring people closer together by offering an authentic culinary experience that reflects the rich tapestry of flavors and traditions from South India, particularly Tamil Nadu.
                </p>
                <p style={{ color: "#858687"  }}>
                At Namma Virundhu, we understand that hosting an event can be stressful. That's why we go beyond just providing food. We take care of all your food-related needs, ensuring a seamless and enjoyable experience for you and your guests. From meticulously planning and preparing each dish to managing the logistics of serving and ensuring smooth operations throughout your event, our dedicated team allows you to focus on what truly matters - enjoying the celebration.
                </p>
              </div>
              <div className="section-image">
                <img src={food2} alt="Food" />
              </div>
            </div>
          </div>
          <div>
          {/* //new */}
            <div className="section">
              <div className="">
            
                <p style={{ color: "#858687", paddingLeft:'24px', width:'96%' }}>
                Our culinary journey begins in the bustling streets of Tamil Nadu, where every dish is a testament to generations of culinary wisdom passed down through families. We carefully select each ingredient, from aromatic spices to vibrant herbs, to create dishes that are not merely meals but unforgettable experiences. Each recipe embodies the essence of traditional South Indian cooking, ensuring authenticity and quality in every bite. We believe that food is more than sustenance; it is a way to preserve and celebrate cultural heritage. Our commitment to tradition ensures that every dish we serve tells a story and evokes a sense of warmth and nostalgia.<br/><br/>
                </p>
                <p style={{ color: "#858687", paddingLeft:'24px', width:'96%' }}>
                Beyond the culinary delights, Namma Virundhu is a celebration of community. It's a place where friends and family gather to share a meal, laugh, and create cherished memories together. Our events are more than just occasions; they are opportunities to connect and strengthen bonds over delicious food and shared cultural experiences.<br/><br/>
                In a world that's constantly changing, Namma Virundhu serves as a reminder of the timeless beauty of tradition. We invite you to experience the magic of Namma Virundhu, where every meal is a celebration of flavor, tradition, and community. Join us on a culinary journey through South India, and let us create an unforgettable dining experience that reflects the heart and soul of our culture.
                </p>
              </div>
              {/* <div className="section-image">
                <img src={food2} alt="Food" />
              </div> */}
            </div>
          </div>
          <div className="restaurant-container">
            <h1 style={{ textAlign: "center" }}>Services</h1>
            <div className="features">
              <div className="feature-item">
                <span className="icon">👨‍🍳</span>
                <h3>Traditional Chefs</h3>
                <p style={{ color: "#858687" }}>
                Our traditional expert chefs are masters of their craft
    
                </p>
              </div>
              <div className="feature-item">
                <span className="icon">🍽️</span>
                <h3>Quality Food</h3>
                <p style={{ color: "#858687" }}>
                we source only the finest, freshest, and highest-quality ingredients for our dishes.
                </p>
              </div>
              <div className="feature-item">
                <span className="icon">🛒</span>
                <h3>Online Order</h3>
                <p style={{ color: "#858687" }}>
                With Namma Virundhu, you can book your event with confidence,
                </p>
              </div>
              <div className="feature-item">
              <span className="icon">📧</span>
                <h3>24/7 Service</h3>
                <p style={{ color: "#858687" }}>
                You can reach our dedicated support service team at nammavirundhu@gmail.com. 
                </p>
              </div>
            </div>
            {/* <div className="about-us">
              <h2>
                Welcome to{" "}
                <span role="img" aria-label="restaurant">
                  🍴
                </span>{" "}
                Restoran
              </h2>
              <p>
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
                diam amet diam et eos erat ipsum et lorem et sit, sed stet lorem
                sit.
              </p>
              <p>
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
                diam amet diam et eos erat ipsum et lorem et sit, sed stet lorem
                sit.
              </p>
              <div className="experience">
                <div className="years">
                  <h3>15</h3>
                  <p>
                    Years of <br /> EXPERIENCE
                  </p>
                </div>
                <div className="chefs">
                  <h3>50</h3>
                  <p>
                    Popular <br /> MASTER CHEFS
                  </p>
                </div>
              </div>
              <button>Read More</button>
            </div> */}
            <div className="gallery"></div>
          </div>
        </>
      );
}

export default About;
